import { CartPack } from "./Pack";

export default interface LabService {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  display_name: string;
  service_category: string;
  allow_multiple_on_checkout: boolean;
  lab: string;
  results_hours: number;
  lab_name: string;
  widgets: string[];
  service: string;
  active: boolean;
  comunas_available: string[];
  price: number;
  final_price: number;
  cost: number;
  promotion: boolean;
  promotion_price: number;
  lab_receipt_amount: number;
};

export interface CheckoutLabService {
  id: string;
  name?: string;
  price?: number;
  service_code?: string;
  isPack?: boolean;
}

export interface SelectedLabService extends CheckoutLabService {
  amount: number;
  fonasa?: boolean;
}

export interface DetailedLabService extends CheckoutLabService {
  id: string;
  lab_name?: string;
  name: string;
  price: number;
  widgets?: string[];
}

export interface CartLabService {
  id: string;
  name: string;
  price: number;
  results_hours: number;
  service_fee: number;
  service_id: number;
  service_category: "kine" | "covid" | "blood" | "urine" | "vaccine";
  fonasa: boolean;
  copago: number;
  bono_fonasa: number;
  code: string;
  allow_multiple_on_checkout: boolean;
  promotion: boolean;
  promotion_price: number;
  lab_receipt_amount: number;
}

export interface SelectedServicesInsurance {
  isFonasa: boolean;
  insurance: string;
}

export const baseLabService = {
  id: "",
  created_at: "",
  updated_at: "",
  name: "",
  display_name: "",
  service_category: "",
  allow_multiple_on_checkout: false,
  lab: "",
  service: "",
  active: false,
  comunas_available: [],
  price: 0,
  final_price: 0,
  results_hours: 0,
  cost: 0,
  lab_receipt_amount: 0,
  lab_name: "",
  widgets: [],
}

export type CartPackOrLabService = CartLabService & { isPack: boolean } | CartPack & { isPack: boolean };
