import React, { useEffect, useState } from "react";
import labService from "../../utils/api/v1/labService";

// typings
import LabService, { baseLabService } from "../../utils/interfaces/LabServices";

// Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Flex from "../Containers/Flex";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { navigate } from "gatsby";
import TextField from "@material-ui/core/TextField";

interface Props {
  name: string;
  labService: LabService;
  labId: string;
  setLabServices: (labServices: LabService[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: any) => void;
  labServices: LabService[];
}

const LabServiceCard = (props: Props): JSX.Element => {
  const [internalLabService, setInternalLabService] = useState<LabService>(
    baseLabService
  );

  useEffect(() => {
    setInternalLabService(props.labService);
  }, [props.labService]);

  const updateLabService = async (): Promise<void> => {
    props.setLoading(true);
    try {
      const req = await labService.updateLabService(
        internalLabService.id,
        internalLabService
      );
      setInternalLabService(req.data);
    } catch (err) {
      console.log(err);
      props.setError(err);
    }
    props.setLoading(false);
  };

  return (
    <Card variant="outlined" style={{ width: '18rem' }}>
      <CardContent>
        <Flex justify="space-between" margin="0 0 2rem 0">
          <FormControlLabel
            control={
              <Switch
                name="active"
                color="primary"
                checked={internalLabService.active}
                onChange={e => {
                  setInternalLabService({
                    ...internalLabService,
                    active: e.target.checked
                  });
                }}
              />
            }
            label={internalLabService.active ? "Activo" : "Inactivo"}
          />
        </Flex>
        <Typography gutterBottom variant="h6" component="h3">
          {props.name}
        </Typography>
        <FormControl>
          <Flex direction="column" justify="space-between" gap="1rem">
            <TextField
              type="amount"
              helperText="Precio"
              value={internalLabService.price}
              onChange={e => {
                setInternalLabService({
                  ...internalLabService,
                  price: parseInt(e.target.value)
                });
              }}
            />
            <TextField
              type="amount"
              value={internalLabService.results_hours}
              helperText="horas para el resutlado"
              error={(internalLabService.results_hours || 0) === 0}
              onChange={e => {
                setInternalLabService({
                  ...internalLabService,
                  results_hours: parseInt(e.target.value || "0")
                });
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                updateLabService();
                props.setLabServices(
                  props.labServices.map((labService: LabService) => {
                    if (labService.id === internalLabService.id) {
                      return internalLabService;
                    }
                    return labService;
                  })
                );
              }}
            >
              Actualizar
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate(`/labservice/${props.labService.id}/schedule`);
              }}
            >
              Horarios
            </Button>
          </Flex>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default LabServiceCard;
