import React, { useState, useEffect } from "react";
import labService from "../../../utils/api/v1/labService";
// typings
import LabService from "../../../utils/interfaces/LabServices";
import Service from "../../../utils/interfaces/Services";

// components
import Bold from "../../../components/Typography/Bold";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Flex from "../../../components/Containers/Flex";
import LaboratoryData from "../../../components/Laboratory/LaboratoryData";
import LabServiceCard from "../../../components/LabServices/LabServiceCard";
import LabServicesModal from "../../../components/LabServices/LabServicesModal";
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import LoadingError from "../../../components/Loaders/LoadingError";
import {
  SpacedPaper,
  SectionHeader,
} from "../../../components/Containers/SpacedPaper";
import CardContainer from "../../../components/Containers/CardContainer";
import servicesService from "../../../utils/api/v1/servicesService";

interface LaboratoryProfileProps {
  id: string;
}

const LaboratoryProfile = (props: LaboratoryProfileProps): JSX.Element => {
  const [loadingLab, setLoadingLab] = useState<boolean>(false);
  const [loadingServices, setLoadingServices] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [laboratoryData, setLaboratoryData] = useState<any>({});
  const [labServices, setLabServices] = useState<LabService[]>([]);
  const [services, setServices] = useState<object[]>([]);
  const [defaultServices, setDefaultServices] = useState<object[]>([]);
  const [selectedServices, setSelectedServices] = useState<object[]>([]);
  const [deletedServices, setDeletedServices] = useState<object[]>([]);
  const [serviceModal, setServiceModal] = useState<boolean>(false);

  const loading = loadingLab || loadingServices;

  const fetchData = async (): Promise<void> => {
    setDeletedServices([]);
    setSelectedServices([]);
    setLoadingLab(true);
    try {
      const reqs = await Promise.all([
        labService.fetchLab(props.id),
        labService.fetchLabServices(props.id),
      ]);
      setLaboratoryData(reqs[0].data);
      setLabServices(reqs[1].data.data);
      setDefaultServices(
        reqs[1].data.data.map((labService: LabService) => ({
          value: labService.name,
          label: labService.name,
          id: labService.service,
          labServiceId: labService.id,
        }))
      );
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoadingLab(false);
    }
  };

  useEffect(() => {
    const fetchServices = async (): Promise<void> => {
      setLoadingServices(true);
      try {
        const req = await servicesService.fetchServices();
        setServices(
          req.data.results.map((service: Service) => ({
            value: service.display_name,
            label: service.display_name,
            id: service.id,
          }))
        );
      } catch (err) {
        console.log(err);
        setError(err);
      } finally {
        setLoadingServices(false);
      }
    };

    fetchData();
    fetchServices();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && (
        <>
          <LabServicesModal
            lab={props.id}
            selectedServices={selectedServices}
            deletedServices={deletedServices}
            defaultServices={defaultServices}
            services={services}
            setDeletedServices={setDeletedServices}
            setSelectedServices={setSelectedServices}
            fetchData={fetchData}
            setLoading={setLoadingLab}
            loading={loading}
            setError={setError}
            modal={serviceModal}
            setModal={setServiceModal}
          />
          <LaboratoryData laboratoryData={laboratoryData} />
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Servicios</Bold>
                <ButtonGroup variant="text">
                  <Button
                    color="primary"
                    onClick={() => {
                      setServiceModal(true);
                      document.getElementById("navbar").style.zIndex = "0";
                    }}
                  >
                    Editar
                  </Button>
                </ButtonGroup>
              </SectionHeader>
              <hr />
              <CardContainer padding="1rem" style={{ display: "flex", flexWrap: "wrap" }}>
                {labServices.map((labService: LabService) => (
                  <LabServiceCard
                    setLoading={setLoadingServices}
                    setError={setError}
                    key={labService.id}
                    labService={labService}
                    name={labService.name}
                    labId={props.id}
                    setLabServices={setLabServices}
                    labServices={labServices}
                  />
                ))}
              </CardContainer>
            </Flex>
          </SpacedPaper>
        </>
      )}
    </PrivateRoute>
  );
};

export default LaboratoryProfile;
