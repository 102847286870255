import React, { useState } from "react";
import styled from "styled-components";

// typings

// components
import { SpacedPaper, SectionHeader } from "../Containers/SpacedPaper";
import Flex from "../Containers/Flex";
import Bold from "../Typography/Bold";
import { Button } from "@material-ui/core";
import { ButtonGroup } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { navigate } from "gatsby";

const LaboratoryDataContainer = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-template-rows: 1fr 1fr;
`;

interface Props {
  laboratoryData: any;
}

const LaboratoryData = (props: Props): JSX.Element => {
  const disabled = !props.laboratoryData.id;
  return (
    <SpacedPaper variant="outlined">
      <Flex direction="column">
        <SectionHeader>
          <Bold>Información del laboratorio</Bold>
          <ButtonGroup variant="text">
            <Button
              color="primary"
              disabled={disabled}
              onClick={() => {
                navigate(`/laboratory/${props.laboratoryData.id}/edit/`);
              }}
            >
              Editar
            </Button>
            <Button
              color="primary"
              disabled={disabled}
              onClick={() => {
                navigate(`/laboratory/${props.laboratoryData.id}/schedule/`);
              }}
            >
              Horarios
            </Button>
            <Button
              color="primary"
              disabled={disabled}
              onClick={() => {
                navigate(`/laboratory/${props.laboratoryData.id}/blocked-schedule/`);
              }}
            >
              Horarios bloqueados
            </Button>
          </ButtonGroup>
        </SectionHeader>
        <hr />
        <Flex padding="2rem" justify="flex-start" gap="10rem">
          <LaboratoryDataContainer>
            <Bold>Nombre: </Bold>
            {props.laboratoryData.display_name}
            <Bold>Dirección: </Bold>
            {props.laboratoryData.address}
          </LaboratoryDataContainer>
          <LaboratoryDataContainer>
            <Bold>Ciudad: </Bold>
            {props.laboratoryData.city}
            <Bold>País: </Bold>
            {props.laboratoryData.country}
          </LaboratoryDataContainer>
          <LaboratoryDataContainer>
            <Bold>Activo: </Bold>
            {props.laboratoryData.active ? "Sí" : "No"}
          </LaboratoryDataContainer>
        </Flex>
      </Flex>
    </SpacedPaper>
  );
};

export default LaboratoryData;
