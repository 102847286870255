import React from "react";
import Swal from "sweetalert2";
import { isEqual, some } from "lodash";
import labService from "../../utils/api/v1/labService";
// typings

// components
import Flex from "../Containers/Flex";
import ModalContainer from "../Containers/ModalContainer";
import Modal from "@material-ui/core/Modal";
import Select from "react-select";
import Wrapper from "../Containers/Wrapper";
import SButton from "../Buttons/SButton";


interface Props {
  lab: string;
  selectedServices: object[];
  deletedServices: object[];
  defaultServices: object[];
  services: object[];
  setDeletedServices: (services: object[]) => void;
  setSelectedServices: (services: object[]) => void;
  fetchData: () => Promise<void>;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  setError: (error: any) => void;
  modal: boolean;
  setModal: (modal: boolean) => void;
}

const LabServicesModal = (props: Props): JSX.Element => {
  const selectServices = async () => {
    props.setLoading(true);
    try {
      await Promise.all(
        props.selectedServices.map((service: any) =>
          labService.createLabService({
            service: service.id,
            lab_id: props.lab,
            is_widget_only: false,
          })
        )
      );
      await Promise.all(
        props.deletedServices.map((service: any) =>
          labService.deleteLabService(service.labServiceId)
        )
      );
      await Swal.fire({
        title: "Servicios actualizados",
        text: "Todos los servicios se han actualizado correctamente",
        icon: "success",
      });
      await props.fetchData();
    } catch (err) {
      console.log(err);
      props.setError(err);
    }
    props.setModal(false);
    props.setLoading(false);
  };

  const handleSelect = (selected: any, action: any) => {
    if (action.action === "select-option") {
      if (some(props.defaultServices, action.option)) {
        props.setSelectedServices(
          props.selectedServices.filter(
            (service: any) => !isEqual(service, action.option)
          )
        );
        if (some(props.deletedServices, action.option)) {
          props.setDeletedServices(
            props.deletedServices.filter(
              (service: any) => !isEqual(service, action.option)
            )
          );
        }
      } else if (!some(props.defaultServices, action.option)) {
        props.setSelectedServices([...props.selectedServices, action.option]);
      }
    } else if (action.action === "remove-value") {
      if (some(props.defaultServices, action.removedValue)) {
        props.setDeletedServices([
          ...props.deletedServices,
          action.removedValue,
        ]);
      } else {
        props.setSelectedServices(
          props.selectedServices.filter(
            (service: any) => !isEqual(service, action.removedValue)
          )
        );
      }
    }
  };
  return (
    <Modal
      open={props.modal}
      onClose={() => {
        props.setModal(false);
        props.setSelectedServices([]);
        props.setDeletedServices([]);
        document.getElementById("navbar").style.zIndex = "1999";
      }}
      style={{ height: "100%" }}
    >
      <ModalContainer>
        <Flex justify="center">
          <Wrapper variant="outlined">
            <h2>Agregar servicios</h2>
            <Select
              isMulti
              isClearable={false}
              options={props.services}
              onChange={handleSelect}
              defaultValue={props.defaultServices}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            <Flex
              align="center"
              justify="center"
              margin="2rem 0 rem 1 rem 0 rem"
            >
              <SButton
                variant="outlined"
                color="secondary"
                disabled={props.loading}
                onClick={() => {
                  props.setModal(false);
                  props.setSelectedServices([]);
                  props.setDeletedServices([]);
                }}
              >
                Cancelar
              </SButton>
              <SButton
                variant="outlined"
                color="primary"
                disabled={props.loading}
                onClick={selectServices}
              >
                Crear
              </SButton>
            </Flex>
          </Wrapper>
        </Flex>
      </ModalContainer>
    </Modal>
  );
};

export default LabServicesModal;
